import React from 'react';
import './App.css';
import Home from './components/Home'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/App.scss'

function App() {
  return (
      <div className="App">
      <Home/>
    </div>
  );
}
export default App;
